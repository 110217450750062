import React from 'react';

const TYPEMAP = {
  'Mennekes': 'Type 2',
};

/**
 * Display one or more station types in UI.
 *
 * @param {string} type A comma separated list of types.
 */
const StationType = (props) => {
  const type = props.type || '';
  const types = (type === '' ? '' : type.split(',').map(t => TYPEMAP[t] || t).join(', '));
  return (<span>{types}</span>);
};

export default StationType;
