class PersistentStorage {

  save(key, value) {
    let stored = JSON.parse(localStorage.getItem('settings') || "{}");
    stored[key] = value;
    localStorage.setItem('settings', JSON.stringify(stored));
  }

  load(key, def = null) {
    let stored = JSON.parse(localStorage.getItem('settings') || "{}");
    return key in stored ? stored[key] : def;
  }

  remove(key) {
    let stored = JSON.parse(localStorage.getItem('settings') || "{}");
    if (key in stored) {
      delete stored[key];
      localStorage.setItem('settings', JSON.stringify(stored));
    }
  }

  has(key) {
    return key in JSON.parse(localStorage.getItem('settings') || "{}");
  }
}

export default new PersistentStorage();
