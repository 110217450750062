import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import './LanguageSelector.css';
import {FormControl, Select} from "@mui/material";

const LanguageSelector = inject('intl')(observer(class LanguageSelector extends Component {
  handleChange(event) {
    this.props.i18n.switchLocale(event.target.value);
  }

  render() {
    return (
      <div className="language-selector">
        <FormControl>
          <Select native
                  inputProps={{
                    name: 'lang',
                    id: 'lang-select',
                  }}
                  onChange={this.handleChange.bind(this)}
                  value={this.props.i18n.locale}
                  className="language-selector__menu"
                  style={{paddingLeft: 0}}
          >
            {this.props.i18n.locales.map(locale =>
              <option key={locale} value={locale}>{locale.toUpperCase()}</option>
            )}
          </Select>
        </FormControl>
      </div>
    )
  }
}));

export default LanguageSelector;
