import React from 'react';
import { inject, observer } from 'mobx-react';
import NotificationOngoingCharge from './NotificationOngoingCharge';
import './NotificationArea.css';

const NotificationArea = inject('store')(observer(({store}) => {
  return (
    <div className="notification-area">
      {store.activeCharge && <NotificationOngoingCharge activeCharge={store.activeCharge} />}
    </div>
  );
}));

export default NotificationArea;
