import React from 'react';
import LanguageSelector from './LanguageSelector';
import BackButton from './BackButton';
import './TopBar.css';

const TopBar = (props) => {
  return (
    <div className="top-bar">
      <BackButton />
      <LanguageSelector i18n={props.i18n} />
    </div>
  );
};

export default TopBar;
