const USE_DEVICE_ID_FOR_OTP_CHARGE_FEATURE_NAME = 'UseDeviceIdForOtpCharge';
const USE_ADYEN_PAYMENT_PROVIDER_FEATURE_NAME = 'USE_ADYEN_PAYMENT_PROVIDER';
const USE_AUTH_HOLD_WEB_FEATURE_NAME = 'USE_AUTHORIZATION_HOLD';

/**
 * Check if UseDeviceIdForOtpCharge flag is enabled
 * @param {int} sellerId 
 * @returns Promise<boolean>
 */
const shouldUseDeviceIdForOtpCharge = async (sellerId) => {
  const enabled = await isFeatureEnabled(
    USE_DEVICE_ID_FOR_OTP_CHARGE_FEATURE_NAME,
    sellerId
  );
  return enabled;
}

/**
 * Check if isAdyenEnabled flag is enabled
 * @param {int} sellerId
 * @returns Promise<boolean>
 */
const isAdyenEnabled = async (sellerId) => {
  const enabled = await isFeatureEnabled(
    USE_ADYEN_PAYMENT_PROVIDER_FEATURE_NAME,
    sellerId
  );
  return enabled;
}

/**
 * Check if authHold feature is enabled
 * @param {int} sellerId
 * @returns Promise<boolean>
 */
const isAuthHoldEnabled = async (sellerId) => {
  const enabled = await isFeatureEnabled(
      USE_AUTH_HOLD_WEB_FEATURE_NAME,
      sellerId
  );
  return enabled;
}

/**
 * Check if feature is enabled for seller
 * @param {string} featureName 
 * @param {number} sellerId 
 * @returns Promise<boolean>
 */
const isFeatureEnabled = async (featureName, sellerId) => {
  const url = `/api/v4/featureflag/${featureName}?sellerId=${sellerId}`;

  try {
    const response = await fetch(url);
    if (response.ok) {
      const jsonRes = await response.json();
      return Boolean(jsonRes.enabled);
    }
  } catch (err) {
    console.log(`Error fetching feature flag: ${featureName}`, err);
  }

  return false;
}

export { shouldUseDeviceIdForOtpCharge, isAdyenEnabled, isAuthHoldEnabled }
