import React from 'react';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

const LoadingIndicator = (props) => {
  return (
    <Card className="box">
      <div className="wrapper__inner wrapper--center">
          <div>
            <h3>{props.intl.getMessage(props.loadingMessage)}</h3>
            <CircularProgress />
          </div>
      </div>
    </Card>
  )
};

LoadingIndicator.propTypes = {
  loadingMessage: PropTypes.string
};

export default LoadingIndicator;