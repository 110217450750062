import React from 'react';
import { inject, observer } from 'mobx-react';
import CircularProgress from '@mui/material/CircularProgress';
import BlinkingBatteryIcon from './BlinkingBatteryIcon';
import ChargeState from '../components/ChargeState';

const ChargeDisplay = inject('intl')(observer(({chargeState, charge, intl}) => {
  let message;
  switch (chargeState) {
    case ChargeState.stopping:
      message = intl.getMessage('chargedisplay_stopping');
      break;
    case ChargeState.waiting:
      message = intl.getMessage('chargedisplay_waiting_to_connect');
      break;
    case ChargeState.charging:
      message = intl.getMessage('chargedisplay_charging');
      break;
    default:
      break;
  }

  if(chargeState === ChargeState.charging && charge) {
    const wh = charge.watt_hour;
    const whColor = (wh > 0) ? '#000' : '#999';

    return (
      <div className="wrapper">
        <h3 id="chargingMessageDivId">{message}</h3>
        <CircularProgress mode="indeterminate" size={80} thickness={5} />
        <BlinkingBatteryIcon style={{fontSize: "48px", position: "absolute", left: "0", right: "0", top: "88px"}} />
        <br/>
        <div className="wrapper flex">
          <div>
            <h2 className="title" style={{ color: whColor, fontSize: '2rem', fontWeight: 'bold', margin: '0' }}>{wh}</h2>
            <span style={{fontSize: '1rem', fontWeight: 'light', color: whColor}}>Wh</span>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="wrapper">
        <h3>{message}</h3>
        <CircularProgress />
      </div>
    );
  }
}));

export default ChargeDisplay;
