function formatKwh(kwh) {
  return Number(kwh).toFixed(2);
}

function formatDurationString(duration, getMessage) {
  duration = parseInt(duration, 10);
  if(duration === 1) {
    return duration + ' ' + getMessage('chargingfinished_duration_second');
  }
  if(duration < 60) {
    return duration + ' ' + getMessage('chargingfinished_duration_seconds');
  }
  if(duration >= 60) {
    let roundedDuration = Math.round(duration / 60);
    if (roundedDuration === 1) {
      return roundedDuration + ' ' + getMessage('chargingfinished_duration_minute');
    } else {
      return roundedDuration + ' ' + getMessage('chargingfinished_duration_minutes');
    }
  }
}

const divideBy100 = amount => amount / 100;

const getLocalPriceWithCurrency = (amount = 0, currency = 'EUR') => {
  // en-150 is general Europe locale with English
  const browserLocale = navigator.language || 'en-150';
  const priceWithCurrency = divideBy100(amount)
    .toLocaleString(browserLocale, { style: 'currency', currency, minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return priceWithCurrency;
};

const getDecimalPrice = (amount = 0) => {
  const browserLocale = navigator.language || 'en-150';
  return divideBy100(amount).toLocaleString(browserLocale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

const formatCurrencySymbol = (currency) => {
  return (0)
    .toLocaleString("en-150", { style: 'currency', currency })
    .replace(/\d+/g,'')
    .replace(/[,. ]/g, '');
};

export { formatKwh, formatDurationString, getDecimalPrice, getLocalPriceWithCurrency, formatCurrencySymbol}
