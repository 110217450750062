import React from 'react';
import './CustomLogo.css';

const CustomLogo = ({url}) => (
  !url ? null :
    <div className="custom-logo">
      <img src={url} alt="Logo" role="presentation" />
    </div>
);

export default CustomLogo;
