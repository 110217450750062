import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import Button from '@mui/material/Button';

const leftBtnStyle = {
  float: 'left',
  height: '56px',
  lineHeight: '0px'
};

const BackButton = inject('intl')(observer(class BackButton extends Component {
  whiteListRoute = '/stations/';

  handleClick() {
    if (this.props.history.location.search) {
      return this.props.history.push(this.props.history.location.pathname);
    }
    return this.props.history.push('/');
  }

  render() {
    if (this.props.history.location.pathname.startsWith(this.whiteListRoute)) {
      return (
          <Button style={leftBtnStyle}
                      onClick={() => this.handleClick()}>
            <i className="material-icons">arrow_back</i>
          </Button>
      );
    }
    return null;
  }
}));

export default withRouter(BackButton);
