import { extendObservable } from 'mobx';
import store from './PersistentStorage';

class ApiStore {
  constructor() {
    extendObservable(this, {
      locale: null,
      activeCharge: null
    });

    this.updateActiveCharge();
  }

  setChargeReceiptSent() {
    let activeCharge = store.load('activeCharge');
    if (activeCharge && !activeCharge.receiptSent) {
      activeCharge.receiptSent = true;
      this.setActiveCharge(activeCharge);
    }
  }
  setActiveCharge(charge) {
    if (charge) {
      store.save('activeCharge', charge);
    }
    this.updateActiveCharge();
  }

  updateActiveCharge() {
    try {
      this.activeCharge = store.load('activeCharge');
    } catch (e) {
      console.error('activeCharge deserialize failed: ', e);
      this.activeCharge = null;
    }
  }

  getStationInfo(stationID) {
    return fetch('/api/stations/' + stationID)
      .then(res => res.json())
      .catch(err => err);
  }

  getStationURL(chargeHostname, stationID) {
    if (!stationID) {
      return Promise.resolve('//' + chargeHostname);
    }

    return this.getStationInfo(stationID)
        .then(station => {
          if (!station.seller.brand) {
            return '//' + chargeHostname + '/stations/' + stationID;
          }
          return '//' + station.seller.brand + '.' + chargeHostname + '/stations/' + stationID;
        })
        .catch(() => '/');
  }

  clearActiveCharge() {
    store.remove('activeCharge');
    this.updateActiveCharge();
  }

  async stopCharging(stationId, cardEnding, sellerId) {
    let url = '';
    // activeCharge found from localStorage, stopping with deviceId (v2) or stopcode (v1)
    if (this.activeCharge) {
      // stop charge with stopcode
      const { stopcode, deviceId } = this.activeCharge;
      stationId = this.activeCharge.stationId;
      if (!stopcode && !deviceId) {
        throw new Error('Stop code / deviceId missing');
      }

      if (this.activeCharge.usingV2Endpoint) {
        url = `/api/charging/stoponetime-v2?deviceId=${deviceId}&stationId=${stationId}`;
      } else {
        url = `/api/charging/stoponetime?stopcode=${stopcode}`;
      }

      const jsonRes = await this.sendStopRequestAndSetActiveCharge(
        url,
        stationId,
        this.activeCharge.usingV2Endpoint,
      );

      return jsonRes;
    }

    // activeCharge not found, stopping with stationId & cardEnding
    if (!cardEnding || !stationId) {
      throw new Error('Cannot stop charge due to missing card ending OR stationId');
    }

    /**
     * For stopping with cardEnding, we will always try with v2 first
     * because if charge was started with v2, stopping with v1 will sometimes return incorrect charge in finished page
     * (due to v1 endpoint does not support multiple charges that belong to same customers and returned only 1st charge).
     * If charge was started with v1, stopping with v2 will fail and we will retry with v1 afterwards
     */
    url = `/api/charging/stoponetime-v2?stationId=${stationId}&cardEnding=${cardEnding}`;
    let usingV2Endpoint = true;

    try {
      const jsonRes = await this.sendStopRequestAndSetActiveCharge(
        url,
        stationId,
        usingV2Endpoint
      );

      return jsonRes;
    } catch (error) {
      // catching request sending error here and ignore to retry v1 endpoint below
    }

    // retry v1 endpoint, to be deleted after migrations to v2 done
    url = `/api/charging/stoponetime?stationId=${stationId}&cardEnding=${cardEnding}`;
    usingV2Endpoint = false;

    const jsonRes = await this.sendStopRequestAndSetActiveCharge(
      url,
      stationId,
      usingV2Endpoint,
    );

    return jsonRes;
  }

  async sendStopRequestAndSetActiveCharge(url, stationId, usingV2Endpoint) {
    try {
      const response = await fetch(url);

      if (response.ok) {
        const jsonRes = await response.json();

        if (!this.activeCharge) {
          // store necessary OTP charge data for checkonetime & receipt API
          this.setActiveCharge({
            stationId,
            deviceId: jsonRes.deviceId,
            otpToken: jsonRes.otpToken,
            usingV2Endpoint,
            receiptSent: false,
            stopcode: jsonRes.deviceId,
          });
        }

        return jsonRes;
      }
      throw new Error(response.status);
    } catch (error) {
      throw new Error('Stopping charge failed');
    }
  }

  checkCharging(token) {
    let url = `/api/charging/checkonetime/?token=${token}`;

    if (this.activeCharge && this.activeCharge.usingV2Endpoint) {
      url = `/api/charging/checkonetime-v2?deviceId=${this.activeCharge.deviceId}`;
    }

    return (
      fetch(url)
        .then(res => {
          if (res.status !== 200) {
            return Promise.reject(res.status);
          } else {
            return res.json();
          }
        })
        .then(charge => {
          this.setActiveCharge(null);
          return Promise.resolve(charge);
        })
        .catch(() => {
          return Promise.reject({
            error: true,
            message: 'Charge not found'
          });
        })
    );
  }
}

export default ApiStore;
