import React from 'react';
import { inject, observer } from 'mobx-react';
import CustomLogo from './CustomLogo';
import './Banner.css';

const Banner = inject('customization')(observer(({children, customization}) => {
  return (
    <div className="banner">
      <div className="banner__wrapper">
        <div className="banner-top">
          {children}
        </div>
        <div className="banner-bottom">
          <CustomLogo {...customization.theme.logo} />
        </div>
      </div>
    </div>
  );
}));

export default Banner;
