import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ApiStore from './stores/ApiStore';
import IntlStore from './stores/IntlStore';
import CustomizationStore from './stores/CustomizationStore';
import UserStore from './stores/UserStore';
import { Provider } from 'mobx-react';

/**
 * After finishing the entire migration process and validating that your project works as expected,
 * consider enabling the flags computedRequiresReaction, reactionRequiresObservable and
 * observableRequiresReaction and enforceActions: "observed" to write more idiomatic MobX code.
 */
import {configure} from "mobx";
configure({ enforceActions: "never" });

const stores = {
  store: new ApiStore(),
  intl: new IntlStore(),
  customization: new CustomizationStore(),
  user: new UserStore(),
};


ReactDOM.render((
  <Provider {...stores}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
), document.getElementById('root'));
