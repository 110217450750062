import React, { Component } from 'react';
import { extendObservable } from 'mobx';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import Dialog from "@mui/material/Dialog";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import './AddCardForm.css';
import CreditCardInfo from './CreditCardInfo';
import CircularProgress from "@mui/material/CircularProgress";
import {Box, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import { isAdyenEnabled } from '../services/feature-flag';

const ReceiptInputDialog = inject('intl')(observer(class ReceiptInputDialog extends Component {

  constructor(props, context) {
    super(props, context);
    const { fields } = props;
    const filterFields = (fields, value) =>
      Object.keys(fields).filter(fieldName => fields[fieldName] === value);
    const optionalFieldNames = filterFields(fields, 'optional');
    this.mandatoryFieldNames = filterFields(fields, 'mandatory');
    this.activeFieldNames = [...this.mandatoryFieldNames, ...optionalFieldNames];
    const fieldContents = {};
    this.activeFieldNames.forEach(fieldName =>
      fieldContents[fieldName] = localStorage.getItem(`receiptField_${fieldName}`) || ''
    );
    this.handleChange = this.handleChange.bind(this);
    extendObservable(this, { fieldContents });
  }

  receiptDialogAborted() {
    console.log('input aborted');
  }

  handleChange(event) {
    this.fieldContents[event.target.id] = event.target.value;
  }

  render() {
    this.validateEmail = (email) => {
      // Email validating RegEx comes from here: http://jsfiddle.net/ghvj4gy9/embedded/result,js/
      const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regexp.test(email);
    }
    this.getErrorText = (fieldName) => {
      if (fieldName === 'email' && this.validateEmail(this.fieldContents['email']) === false) {
        return intl.getMessage('receiptinputdialog_invalid_email')
      } else return isFieldValid(fieldName) ? '' : intl.getMessage('receiptinputdialog_mandatory')
    }
    const { intl, isOpen, onClose } = this.props;
    const title = intl.getMessage('receiptinputdialog_title');
    const isFieldValid = (fieldName) => {
      if (fieldName === 'email') {
        return this.validateEmail(this.fieldContents[fieldName]);
      }
      return this.mandatoryFieldNames.indexOf(fieldName) < 0 || this.fieldContents[fieldName].length > 0;
    }
    const areAllMandatoryFieldsValid =
      this.mandatoryFieldNames.every(name => isFieldValid(name));

    return (
      <Dialog
        onClose={onClose}
        open={isOpen}
        onAbort={this.receiptDialogAborted}

        fullWidth={true}
        maxWidth={"lg"}
        scroll={"paper"}
        sx={{
          maxHeight: '100% !important',
          height: "auto"
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>

        <Box component={"form"}
             noValidate
        >
        {this.activeFieldNames.map((fieldName, i) =>
          <TextField
            id={fieldName}
            variant={"outlined"}
            key={i}
            value={this.fieldContents[fieldName]}
            label={intl.getMessage(`receiptinputdialog_field_${fieldName}`)}
            aria-errormessage={this.getErrorText(fieldName)}
            aria-label={fieldName}
            helperText={(!isFieldValid(fieldName) && this.getErrorText(fieldName)) || ''}
            required={this.mandatoryFieldNames.includes(fieldName)}
            onChange={this.handleChange}
            style={{ width: '45%', marginRight: '5%'}}
            margin={"normal"}
            InputLabelProps={{shrink: true}}
            error={!isFieldValid(fieldName)}
          />
        )}
        </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant={"contained"}
            disabled={!areAllMandatoryFieldsValid}
            onClick={() => {
              this.activeFieldNames.forEach(name => {
                localStorage.setItem(`receiptField_${name}`, this.fieldContents[name]);
              });
              onClose();
            }}
          >{intl.getMessage('stationsearch_label_ok')}</Button>
        </DialogActions>
      </Dialog>
    );
  }
}));

const AddCardForm = inject('intl', 'customization', 'user')(observer(class AddCardForm extends Component {

  constructor(props, context) {
    super(props, context);
    this.doNotUseOldCard = this.doNotUseOldCard.bind(this);
    this.useOldCard = this.useOldCard.bind(this);
    this.submitCardAddingForm = this.submitCardAddingForm.bind(this);
    // If otp receipt sending is mandatory, we need the email address to be mandatory also
    if (this.props.otpReceipt === "mandatory") {
      this.props.receiptInputFields['email'] = "mandatory";
    }
    this.haveActiveReceiptInputFields =
      this.props.receiptInputFields &&
      !Object.keys(this.props.receiptInputFields).every(fieldName =>
        this.props.receiptInputFields[fieldName] === 'off');
    extendObservable(this, { isReceiptInputDialogOpen: false });
    this.handleReceiptInputDialogClose = () => console.error('not implemented');
    this.submitAddCreditCardForm = () => {
      this.setState({ isLoading: true })
      this.submitCardAddingForm();
    };
    this.state = {
      isLoading: false,
      isPspCardMatchingWithAdded: false
    }
  }

  componentDidMount() {
    this.cardIsSamePspAsSellerhasEnabled();
  }

  cardIsSamePspAsSellerhasEnabled() {
    isAdyenEnabled(this.props.station.seller.otpSeller || this.props.station.seller_ID).then((ret) => {
      this.setState({isPspCardMatchingWithAdded: (ret === this.props.user.isAdyen)});
    });
  }

  submitCardAddingForm(isCardChange = false) {
    // fetch the actual URL from API.
    const callbackBaseUrl = window.location.origin;
    const stationId = this.props.station.station_ID;
    const deviceId = this.props.user.deviceId;
    let uri = `/api/payments/addcard-v2?language=EN&callbackBaseUrl=${callbackBaseUrl}&callbackPath=/stations/${stationId}&stationId=${stationId}`;
    if (deviceId !== null) {
      uri = uri + `&deviceId=${deviceId}`;
    }
    fetch(uri)
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          return Promise.reject(`Addcard failed with status ${res.status}`);
        }
      })
      .then(addCardForm => {
        if (isCardChange) {
          this.props.user.clearCreditCard();
        }
        window.location.replace(addCardForm.action);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        this.props.setError(this.props.intl.getMessage('creditcardinfo_error_unknown'));
      });
  }

  useOldCard() {
    // TODO: what is the correct way to do this in React?
    document.location = (window.location.pathname +
      '?response=success&cardToken=' + this.props.user.creditCard.cardToken +
      '&cardSphToken=' + this.props.user.creditCard.cardSphToken +
      '&cardType=' + this.props.user.creditCard.cardType +
      '&cardPartialPan=' + this.props.user.creditCard.cardPartialPan +
      '&cardExpireYear=' + this.props.user.creditCard.cardExpireYear +
      '&deviceId=' + this.props.user.deviceId);
  }

  clearOldCard() {
    this.submitCardAddingForm(true);
  }

  doNotUseOldCard() {
    this.setState({ isLoading: true });
    if (this.haveActiveReceiptInputFields) {
      this.handleReceiptInputDialogClose = this.clearOldCard;
      this.isReceiptInputDialogOpen = true;
    } else {
      this.clearOldCard();
    }
  }

  render() {
    const receiptInputDialog =
      this.haveActiveReceiptInputFields && (
        <ReceiptInputDialog
          fields={this.props.receiptInputFields}
          isOpen={this.isReceiptInputDialogOpen}
          onClose={() => this.handleReceiptInputDialogClose()}
        />
      );

    if (this.props.user.hasCreditCard && this.state.isPspCardMatchingWithAdded) {
      return (
        <div>
          {receiptInputDialog}
          <form id='old-credit-card'
            className='add-card-form'
            //ref={(form) => this.form = form}
          >
            <CreditCardInfo intl={this.props.intl} cardType={this.props.user.creditCard.cardType} cardPartialPan={this.props.user.creditCard.cardPartialPan} />
            <div className="wrapper">
              <div className="change-card">
                <a onClick={this.doNotUseOldCard}>{this.props.intl.getMessage('creditcardinfo_change_payment_method')}</a>
              </div>
              <Button
                variant={"contained"}
                className='add-card-form-submit'
                color={"primary"}
                onClick={() => {
                  if (this.haveActiveReceiptInputFields) {
                    this.handleReceiptInputDialogClose = this.useOldCard;
                    this.isReceiptInputDialogOpen = true;
                  } else {
                    this.useOldCard();
                  }
                }}
              >
                {this.props.intl.getMessage('creditcardinfo_continue')}
              </Button>
            </div>
            {this.state.isLoading && <CircularProgress className={'change-card-loader'} />}
          </form>
        </div>
      );
    }


    return (<div>
      {receiptInputDialog}
      <form id='add-card-form'
        className='add-card-form'>
        <Button
          variant={"contained"}
          className='add-card-form-submit'
          color={"primary"}
          disabled={this.state.isLoading}
          onClick={() => {
            if (this.haveActiveReceiptInputFields) {
              this.handleReceiptInputDialogClose = this.submitAddCreditCardForm;
              this.isReceiptInputDialogOpen = true;
            } else {
              this.submitAddCreditCardForm();
            }
          }}
        >{this.props.intl.getMessage('addcardform_charge_using_payment_method')}</Button>
        {this.state.isLoading && <CircularProgress size={24} className='add-card-form-loader' />}
      </form>
    </div>);
  }
}));

AddCardForm.propTypes = {
  receiptInputFields: MobxPropTypes.observableObject,
  otpReceipt: PropTypes.oneOf(['off','optional','mandatory']),
};

export default AddCardForm;
