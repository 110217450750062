import React, { Component } from 'react';
import { Route } from 'react-router';
import {Switch, withRouter} from 'react-router-dom';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import TopBar from './components/TopBar';
import NotificationArea from './components/NotificationArea';
import ReactGA from 'react-ga';
import './App.css';
import Station from './components/Station';
import Charging from './components/Charging';
import ChargingFinished from './components/ChargingFinished';
import ChgeRedirect from "./components/ChgeRedirect";
import FindCharger from "./components/FindCharger";
import { ThemeProvider } from '@mui/material/styles';
const App = inject('store', 'customization', 'intl')(observer(class App extends Component {

  constructor(props) {
    super(props);

    this.gaInitialized = false;
  }

  pageView() {
    let ga_code = this.props.customization.settings.ga_code;
    if (!ga_code) {
      return;
    }
    if (!this.gaInitialized) {
      this.gaInitialized = true;
      if (ga_code) {
        ReactGA.initialize(ga_code);
      }
    }
    const pathname = window.location.pathname;
    ReactGA.set({ page: pathname });
    ReactGA.pageview(pathname);
  }

  componentDidMount() {
    reaction(
      () => this.props.customization.defaultLocale,
      locale => this.props.intl.setDefaultLocale(locale)
    );
  }

  render() {
    if(!this.props.customization.loaded || !this.props.intl.locale) {
      return (<div />);
    }

    const routingSwitch =
      <Switch>
        <Route exact path="/">
          <FindCharger />
        </Route>
        <Route exact path="/:id">
          <ChgeRedirect />
        </Route>
        <Route path="/stations/:id">
          <Station />
        </Route>
        <Route path="/charging/:token/finished">
          <ChargingFinished />
        </Route>
        <Route path="/charging/:token">
          <Charging />
        </Route>
      </Switch>;

    this.pageView();
    return (
      <ThemeProvider theme={this.props.customization.muiTHemeV1}>
        <div className="App">
            <TopBar i18n={this.props.intl} />
          <NotificationArea {...this.props} />
          {this.props.children}
          {routingSwitch}
        </div>
      </ThemeProvider>
    );
  }
}));

export default withRouter(App);
