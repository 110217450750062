import qs from 'qs';
import { reaction, extendObservable } from 'mobx';
import {createTheme} from "@mui/material";
import Color from "color";

const R = require('ramda')
const DEFAULT_THEME = {};
const DEFAULT_SETTINGS = {};
const DEFAULT_LOCALE = 'en';

class CustomizationStore {

  constructor() {
    extendObservable(this, {
      loaded: false,
      preview: null,
      host: null,
      domainHostname: null,
      brand: null,
      get registerService() {
        return (
          this.brand &&
          this.brand.services &&
          this.brand.services.find((service) => service.type === 'register')
        );
      },
      get registerPageUrl() {
        return this.registerService && this.registerService.parkedUrl;
      },
      get chargeHostname() {
        return 'charge.' + this.domainHostname;
      },
      get defaultLocale() {
        return R.path(['default_locale'], this.brand) || DEFAULT_LOCALE;
      },
      get theme() {
        if(this.preview) {
          return this.preview.theme;
        } else {
          return R.path(['service', 'activeCustomization', 'theme'], this.host) || DEFAULT_THEME;
        }
      },
      get impressumLink() {
        return R.path(['service', 'brand', 'settings', 'impressumLink'], this.host) || null;
      },
      get settings() {
        return R.path(['service', 'activeCustomization', 'settings'], this.host) || DEFAULT_SETTINGS;
      },
      get muiTHemeV1() {
        const brandColor = Color(this.theme.brandColor || '#003748')
        return createTheme({
          palette: {
            primary: {
              light: brandColor.lighten(0.3),
              main: this.theme.brandColor || '#003748', // primary1Color
              dark: brandColor.darken(0.3)
            },
            secondary: {
              main: '#FED100', // primary2Color
            }
          },
          typography: {
            fontFamily: 'Montserrat, sans-serif',
          }
        });
      }
    });

    this.fetchHost(window.location.hostname);
    this.loadPreview();
    this.unbindHostReaction = 
      reaction(
        () => this.host,
        (host) => {
          if(host && host.service) {
            this.fetchBrand(host.service.brand_code);
          } else {
            this.loaded = true;
          }
        }
      );
    this.unbindThemeReaction =
      reaction(
        () => this.theme,
        (theme) => {
          const backgroundUrl = R.path(['background', 'url'], theme)
          if(backgroundUrl) {
            document.body.style.backgroundImage = `url(${backgroundUrl})`;
          } else {
            document.body.style.backgroundImage = 'none';
          }
        }
      );
  }

  fetchHost(hostname) {
    fetch(`/api/hosts/${hostname}`)
        .then(res => {
          this.domainHostname = res.headers.get('x-domain')
          return res.json()
        })
        .then(host => {
          this.host = host;
        }).catch(err => {
      // console.info("No customization found")
    });
  }

  fetchBrand(brandcode) {
    fetch(`/api/brands/${brandcode}`)
      .then(res => res.json())
      .then(brand => {
        this.brand = brand;
        this.loaded = true;
      });
  }

  loadPreview() {
    if(window.location.search.length > 1) {
      const parsed = qs.parse(window.location.search.substr(1));
      if(parsed && parsed._customizationPreview) {
        this.preview = JSON.parse(parsed._customizationPreview);
      }
    }
  }
}

export default CustomizationStore;
