import { extendObservable } from 'mobx';
import messages from '../locales';
import store from './PersistentStorage';

const DEFAULT_LOCALE = 'en';

class IntlStore {
  constructor() {
    this.switchLocale = this.switchLocale.bind(this);
    this.getMessage = this.getMessage.bind(this);
    this.getMessageWithParameters = this.getMessageWithParameters.bind(this);
    extendObservable(this, {
      locale: null,
      get locales() {
        return Object.keys(messages).map(lang => lang);
      }
    });
    this.locale = store.load('locale', DEFAULT_LOCALE);
  }

  switchLocale(locale) {
    if(locale in messages) {
      this.locale = locale;
    } else {
      this.locale = DEFAULT_LOCALE;
    }
    store.save('locale', this.locale);
  }

  setDefaultLocale(locale) {
    if (!store.has('locale') && (locale in messages)) {
      this.locale = locale;
    }
  }

  getMessage(key) {
    return messages[this.locale][key];
  }

  getMessageWithParameters(key, parameters = {}) {
    let ret = this.getMessage(key);
    for (let param_key in parameters) {
      ret = ret.replace(new RegExp(`{{${param_key}}}`,"g"), parameters[param_key]);
    }
    return ret;
  }

  getLocale() {
    return this.locale;
  }
}

export default IntlStore;
