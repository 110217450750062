import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import LoadingIndicator from './LoadingIndicator';

const ChgeRedirect = inject('store', 'intl', 'customization')(observer(class ChgeRedirect extends Component {

  componentDidMount() {
    this.redirectToBrandedURL(this.props);
  }

  redirectToBrandedURL(props) {
    let stationID = props.match.params.id;
    props.store.getStationURL(this.props.customization.chargeHostname, stationID)
      .then(url => {
        window.location = url;
      });
  }

  render() {
    return (
      <main className="main">
        <LoadingIndicator intl={this.props.intl} />
      </main>
    );
  }

}));

export default withRouter(ChgeRedirect);
