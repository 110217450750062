import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { inject, observer } from 'mobx-react';
import Content from '../components/Content';
import ChargeState from '../components/ChargeState';
import ChargeDisplay from '../components/ChargeDisplay';

const StopButtonStyles = {
  width: 'auto',
  display: 'inline-block',
  margin: '16px auto auto'
};

const CHARGING_POLL_INTERVAL = 4000;

const Charging = inject('store', 'intl', 'user')(observer(class Charging extends Component {

  constructor(props) {
    super(props);

    this.state = {
      charge: null,
      chargeState: ChargeState.notStarted
    };
  }

  componentDidMount() {
    this._pollChargeStatusTimer = setInterval(this.checkCharging, CHARGING_POLL_INTERVAL);
    this.checkCharging();
  }

  componentWillUnmount() {
    clearInterval(this._pollChargeStatusTimer);
  }

  mapChargeStateFromCheckOneTimeApi(res) {
    switch (res.status) {
      case 'charging':
        return ChargeState.charging;
      case 'waiting':
        // this state is not available in /me/charges/latest
        return ChargeState.waiting;
      case 'finished':
        if (res.receiptFields) {
          return ChargeState.finished;
        } else {
          // not sure how to even get here yet since receipt is created upon finishing charge
          // as a result, the receipt field is always there for finished charge
          return ChargeState.stopping;
        }
      default:
        throw new Error(`Unable to map status '${res.status}' from checkonetime api in Charging component.`);
    }
  }

  checkCharging = () => {
    const token = this.props.match.params.token;
    const chargeStartedByCustomer = localStorage.getItem('charge_start_initiated_by_customer');
    this.props.store.checkCharging(token)
      .then(res => {
        const newState = this.mapChargeStateFromCheckOneTimeApi(res);

        if (newState === ChargeState.finished) {
          /**
           * There can be multiple charges associated to the same deviceId customer
           * As a result, this endpoint will return the previously finished charge (if the new charge was still starting)
           * We need to check if the charge was recently started by customer and set the state to waiting instead
           */
          if (chargeStartedByCustomer) {
            return this.setState({
              chargeState: ChargeState.waiting,
            })
          }

          this.props.history.push(`/charging/${token}/finished`);
          return;
        }

        if (this.state.chargeState !== ChargeState.stopping) {
          this.setState({
            chargeState: newState,
            charge: res.charge
          });
        }

        // if it goes here, status is not waiting anymore, remove this from localStorage
        localStorage.removeItem('charge_start_initiated_by_customer');
      })
      .catch(err => {
        /**
         * New endpoint /checkonetime-v2 returns 400 response instead of 200 if a charge is not yet created after starting
         * We check if the charge was recently started by the customer. If it was, set status to waiting
         */
        if (chargeStartedByCustomer) {
          return this.setState({
            chargeState: ChargeState.waiting,
          });
        }

        // if not, go back to front page as before
        this.props.history.push('/');
        console.log('Could not check charging: ' + err.error);
      });
  }

  stopCharging = () => {
    if (this.state.chargeState === ChargeState.waiting) {
      this.props.store.clearActiveCharge();
      this.props.history.push('/');
      return;
    }
    this.setState({
      chargeState: ChargeState.stopping
    });
    this.props.store.stopCharging()
      .then(() => {
        this.checkCharging();
      })
      .catch(err => {
        alert(this.props.intl.getMessage('chargingongoing_stop_charging_failed'));
        this.setState({
          chargeState: ChargeState.stopFailed
        });
      });
  }

  render() {
    if (this.state.chargeState === ChargeState.notStarted) {
      return <div />;
    }
    let actionButtons = (
      <div style={{ 'textAlign': 'center' }}>
        <Button id="stopChargingBtn"
                variant={"contained"}
                onClick={this.stopCharging}
                style={StopButtonStyles}
                primary={true}>
          {this.props.intl.getMessage('chargingongoing_stop_charging')}
        </Button>
      </div>);
    return (
      <Content>
        <Card className="box">
          <div className="wrapper__inner wrapper--center">
            <ChargeDisplay chargeState={this.state.chargeState} charge={this.state.charge} />
          </div>
          {this.state.chargeState !== ChargeState.stopping && actionButtons}
        </Card>
      </Content>
    );
  }
}));

export default withRouter(Charging);
