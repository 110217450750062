import React from 'react';
import PropTypes from 'prop-types';

const CreditCardInfo = (props) => {
  return (
    <div id="chargePayingWithCreditCard" className="wrapper">
      <h3 className="title">{props.intl.getMessage('creditcardinfo_paying_with')}</h3>
      <img src={`/images/${props.cardType.toLowerCase()}.png`} alt={props.cardType} className={props.cardType === 'twint' ?  "cardImageTwint" : "cardImage"}/>
      {props.cardType !== 'twint' ? (<span className="cardNumber">XXXX XXXX XXXX {props.cardPartialPan}</span>) : (<span></span>)}
    </div>
  );
};

CreditCardInfo.propTypes = {
  cardType: PropTypes.string.isRequired,
  cardPartialPan: PropTypes.string.isRequired
};

export default CreditCardInfo;
