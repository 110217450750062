import Card from '@mui/material/Card';
import React from 'react';
import StationType from './StationType';
import PropTypes from 'prop-types';

const makeSocketsList = (stationConnectorInfo) => {

  if (stationConnectorInfo && stationConnectorInfo.length > 0) {
    return stationConnectorInfo.map((connector) => {
      return [connector.connector_id, connector.type];
    });
  }

  return [];
};

const ChargingSocketSelector = (props) => {
  if (!props.socketSelectionRequired) {
    return null;
  }

  let socketList = makeSocketsList(
    props.connectorInfo
  );

  const buttons = socketList.map((socket) => {
    const [key, value] = socket;

    return (
      <button
        type="button"
        key={key + value}
        className={"button button--raised choosable " + (props.activeSocket === key ? 'choosable--active' : '')}
        onClick={() => {
          props.onChangeSocket(key, value)
        }}>
        <StationType type={value} />
      </button>
    );
  });

  return (
    <Card className="box">
      <div className="wrapper__inner wrapper--center">
        <div id={props.divId} className="choosables">
          <h2 className="title">{props.intl.getMessage('chargingsocketselector_select_connector')}</h2>
          {buttons}
        </div>
      </div>
    </Card>
  );
};

ChargingSocketSelector.propTypes = {
  divId: PropTypes.string,
  connectorInfo: PropTypes.arrayOf(
    PropTypes.shape({
      connector_id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      status: PropTypes.string,
      current_type: PropTypes.string.isRequired,
    })
  ),
  socketSelectionRequired: PropTypes.bool,
  onChangeSocket: PropTypes.func.isRequired
};

export default ChargingSocketSelector;
