import React from 'react';
import { getDecimalPrice, getLocalPriceWithCurrency, formatCurrencySymbol } from '../utils/formatters';
import './ChargingPriceTable.css';

const ChargingPriceTable = ({intl, station, brandColor}) => {
  let otpSeller;
  if ('undefined' === typeof station.seller.sellerSettings || 'undefined' === typeof station.seller.sellerSettings.otpSeller) {
    otpSeller = station.seller;
  } else {
    otpSeller = station.seller.sellerSettings.otpSeller;
  }
  const { currency, onetimeminimum } = otpSeller;
  const currencySymbol = formatCurrencySymbol(currency);

  let hasAC = false;
  let hasDC = false;
  let dcMinuteCents, acHourCents, powerCents, transactionFee, minutesWithoutTimeCharge=0;

  if(!station.connector_info) {
    station.connector_info = [];
  }

  let connectorInfoLength = station.connector_info.length;
  for (let i = 0; i < connectorInfoLength; i++) {
    if(station.connector_info[i].current_type === 'AC') {
      hasAC = true;
    }
    if(station.connector_info[i].current_type === 'DC') {
      hasDC = true;
    }
  }

  const pricePerKwh = station?.oneTimePricing.find((price) => price.name === 'price_per_kwh');
  powerCents = pricePerKwh.priceCents ?? 0;

  const initialChargeFee = station?.oneTimePricing.find((price) => price.name === 'price_initial_charge_fee');
  transactionFee = initialChargeFee.priceCents ?? 0;

  if(hasDC) {
    const pricePerMin = station?.oneTimePricing.find((price) => price.name === 'price_per_minute');
      dcMinuteCents = pricePerMin?.priceCents ?? 0;
  }

  if(hasAC) {
    const pricePerHour = station?.oneTimePricing.find((price) => price.name === 'price_per_hour');
    acHourCents = pricePerHour.priceCents ?? 0;
  }
  if (station.minutesWithoutTimeCharge) {
    minutesWithoutTimeCharge = station.minutesWithoutTimeCharge;
  }

  let minWithoutTimeChargeHtml = false;
  if (minutesWithoutTimeCharge) {
    minWithoutTimeChargeHtml =
      <p id="minutesWithoutTimeCharge" className="charging-price-additional-info">
        <span> {intl.getMessageWithParameters('chargingpricetable_minutes_without_time_charge', {'cur': currencySymbol})} </span>
        <span style={{fontWeight: 'bold'}}>
          { `${minutesWithoutTimeCharge}${intl.getMessage('minute_unit')}` }
        </span>
      </p>
  }

  return (
    <div className="charging-price-table">
      <h3 className="title">{intl.getMessage('chargingpricetable_pricing')}</h3>
      <table>
        <thead>
        <tr>
          <th style={{backgroundColor: brandColor}}/>
          <th style={{backgroundColor: brandColor}}>{currencySymbol} / kWh</th>
          <th style={{backgroundColor: brandColor}}>+</th>
          <th style={{backgroundColor: brandColor}}>{intl.getMessage('chargingpricetable_header_time_rate')}</th>
          <th style={{backgroundColor: brandColor}}>+</th>
          <th style={{backgroundColor: brandColor}}>{intl.getMessage('chargingpricetable_header_starting_fee')}</th>
        </tr>
        </thead>
        <tbody>
          {hasAC &&
            <tr>
              <th>AC</th>
              <td>{station.free_usage ? 0 : getDecimalPrice(powerCents)}</td>
              <td/>
              <td>{station.free_usage ? 0 : getDecimalPrice(acHourCents)}  {`${currencySymbol} / ${intl.getMessage('hour_unit')}`}</td>
              <td/>
              <td>{station.free_usage ? 0 : getDecimalPrice(transactionFee)}</td>
            </tr>
          }
          {hasDC &&
            <tr>
              <th>DC</th>
              <td>{station.free_usage ? 0 : getDecimalPrice(powerCents)}</td>
              <td/>
              <td>{station.free_usage ? 0 : getDecimalPrice(dcMinuteCents)} {`${currencySymbol} / ${intl.getMessage('minute_unit')}`}</td>
              <td/>
              <td>{station.free_usage ? 0 : getDecimalPrice(transactionFee)}</td>
            </tr>
          }
        </tbody>
      </table>
      {!station.free_usage &&
        <div>
          <p id="chargeMinimumPayment" className="charging-price-additional-info">
            <span>{intl.getMessage('chargingpricetable_minimum_payment')} </span>
            <span style={{fontWeight: 'bold'}}>
              { getLocalPriceWithCurrency(onetimeminimum, currency) }
            </span>
          </p>
          {minWithoutTimeChargeHtml}
        </div>
      }
    </div>
  );
};

export default ChargingPriceTable;
