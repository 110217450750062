import React from 'react';
import './BannerAdvertisement.css';

const BannerAdvertisement = ({enabled, url}) => {
  if(!enabled || !url) {
    return null;
  } else {
    return (
      <div className="banner-advertisement">
        <img src={url} alt="Banner" />
      </div>
    );
  }
};

export default BannerAdvertisement;
