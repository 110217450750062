import React from 'react';
import PropTypes from 'prop-types';

const ImpressumLink = (props) => {
  return (
    <div>
        <a 
            style={
                {
                    paddingLeft: 0,
                    color: '#00bfff',
                    cursor: 'pointer',
                }
            }
            target='_blank'
            rel='noreferrer'
            href={props.link}>
            {props.intl.getMessage('impressum_link')}
        </a>
    </div>
  );
};

ImpressumLink.propTypes = {
    link: PropTypes.string
};

export default ImpressumLink;
