import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Content from '../components/Content';
import Banner from '../components/Banner';
import ImpressumLink from '../components/ImpressumLink';
import './FindCharger.css';
import {
  Autocomplete,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  TextField,
  Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import {Close, Search} from "@mui/icons-material";
import { v4 as uuidv4 } from 'uuid';

const stationNotFound = url => url && url.trim() === '/';

const FindCharger = inject('store', 'intl', 'customization')(observer(class FindCharger extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      dataSource: [],
      stationId: 0,
      dialogOpen: false,
      dialogMessage: null,
      openButtonEnabled: false,
      isLoading: false,
      searchId: null
    };
  }

  onAutoCompleteSelect = (event, value, reason) => {
    const stationId = /\w+\s[/]/.exec(value) ? value.split('/')[0] : value;
    this.setState({
      stationId: stationId,
      searchId: null
    });
    this.goToStation(event, stationId);
  }

  onAutoCompleteUpdate = (event, value, reason) => {
    let chosen = event.target.value;
    // console.log('Updating value ', chosen);
    let uuid = uuidv4();
    this.setState({
      searchId: uuid,
      dialogOpen: false,
    });
    setTimeout(() => this.fetchStations(chosen, uuid), 525);
  }

  fetchStations = (search, uuid) => {
    if (this.state.searchId !== uuid) {
      // console.log('Stale search, skipping');
      return;
    }
    this.setState({
      stationId: search,
      openButtonEnabled: !!search,
    });
    if (search === '') {
        this.setState({dataSource: []});
    } else {
      this.setState({ isLoading: true });
      fetch('/api/stations/?search=' + search)
        .then(res => res.json())
        .then(stations => {
          if (this.state.searchId === uuid) {
            const dataSource = stations.map((s) => String(s.station_ID) +
              (
                (s.roaming_identifier_cpo)
                  ? (s.custom_evse_id)
                    ? ' / ' + String(s.roaming_identifier_cpo) + '*E' + String(s.custom_evse_id)
                    : ' / ' + String(s.roaming_identifier_cpo) + '*E' + String(s.station_ID)
                  : ''
              )
            );
            this.setState({ dataSource, isLoading: false });
          }
        })
        .catch(err => {
          console.log(err);
          this.setState({ isLoading: false });
        });
    }
  }

  goToStation = (event, stationId) => {
    if ( stationId ) {
      this.setState({ openButtonEnabled: false, isLoading: true });
      this.props.store.getStationURL(this.props.customization.chargeHostname, stationId)
        .then(url => { 
          if(stationNotFound(url)) {
            this.setState({
              dialogOpen: true,
              dialogMessage: this.props.intl.getMessage('stationsearch_invalid_station_number'),
              openButtonEnabled: true,
              isLoading: false,
            })        
          } else {
            window.location = url;
          }
        });
    } 
  }

  handleRequestClose = () => {
    this.setState({
      dialogOpen: false,
      dialogMessage: '',
      isLoading: false,
      openButtonEnabled: true,    
    });
  }

  render() {
    const {intl} = this.props;
    const { dialogOpen, dataSource, openButtonEnabled, isLoading} = this.state;

    return (
      <Content
        className="find-charger"
        banner={
          <Banner>
            <h4 style={{color: '#333', textAlign: 'center', fontWeight: 'bold'}}>
              {intl.getMessage('stationsearch_pay_with_ccard')}
            </h4>
          </Banner>}>
        <Card className="box">
          <div className="wrapper">
            <h3 className="title">{intl.getMessage('stationsearch_find_charger_by_number_id')}</h3>
            <Autocomplete
              autoComplete={true}
              autoHighlight={true}
              autoSelect={true}
              blurOnSelect={false}
              clearOnBlur={true}
              color={"primary"}
              noOptionsText={''}
              options={dataSource}
              popupIcon={<Search color={"primary"} />}
              clearIcon={<Close />}
              fullWidth={true}
              onChange={this.onAutoCompleteSelect}
              open={dataSource.length > 0}
              ref="autocomplete"

              filterOptions={(searchText) => searchText}
              renderInput={(params) => (
                <TextField {...params} placeholder="0000/DE*CDE*AABB00001*1"
                           onChange={this.onAutoCompleteUpdate}
                           onBlur={this.onAutoCompleteUpdate}
                           color={"primary"}
                />
              )}
             />
            <Typography component={"p"}>&nbsp;</Typography>
            <Button variant={"contained"}
                    className="find-charger-button"
                    onClick={(e) => this.onAutoCompleteSelect(e, this.state.stationId )}
                    color={"primary"}
                    disabled={!openButtonEnabled}>
              {intl.getMessage('stationsearch_open')}
            </Button>
            { isLoading && (<CircularProgress mode="indeterminate" size={25}/>)}
          </div>
          { this.props.customization.impressumLink && (
            <div style={{paddingTop: '2rem', paddingLeft: '16px'}}>
              <ImpressumLink intl={intl} link={this.props.customization.impressumLink}/>
            </div>
          )}
        </Card>
        <Dialog
          maxWidth={"sm"}
          actions={
            <Button variant={"contained"}
                    onClick={this.handleRequestClose}>
              {intl.getMessage('stationsearch_label_ok')}
            </Button>
          }
          open={dialogOpen}
          onClose={this.handleRequestClose}
        ><DialogContent>
          <DialogContentText>
            {this.state.dialogMessage}
          </DialogContentText>
        </DialogContent></Dialog>
      </Content>
    );
  }
}));

export default (FindCharger);
