import { extendObservable } from 'mobx';

class UserStore {
  constructor() {
    extendObservable(this, {
      // TODO: Add the rest and implement needed for logging in and out.
      isLoggedIn: false,
      creditCard: {
        'cardToken': localStorage.getItem('cardToken'),
        'cardType': localStorage.getItem('cardType'),
        'cardPartialPan': localStorage.getItem('cardPartialPan'),
        'cardExpireYear': localStorage.getItem('cardExpireYear'),
        'cardSphToken': localStorage.getItem('cardSphToken')
      },
      deviceId: localStorage.getItem('deviceId'),
    });
  }

  get hasCreditCard() {
    return this.creditCard.cardToken && this.creditCard.cardType && this.creditCard.cardPartialPan && this.creditCard.cardSphToken && this.deviceId;
  }

  get isAdyen() {
    return this.creditCard.cardToken === "cardTokenNotUsedInVirtaPsp" && this.creditCard.cardSphToken === "sphTokenizationIdNotUsedInVirtaPsp";
  }

  saveCreditCard(card, deviceId) {
    this.creditCard.cardToken = card && card.cardToken;
    this.creditCard.cardType = card && card.cardType;
    this.creditCard.cardPartialPan = card && card.cardPartialPan;
    this.creditCard.cardExpireYear = card && card.cardExpireYear;
    this.creditCard.cardSphToken = card && card.cardSphToken;
    this.deviceId = deviceId;

    localStorage.setItem('cardToken', this.creditCard.cardToken);
    localStorage.setItem('cardType', this.creditCard.cardType);
    localStorage.setItem('cardPartialPan', this.creditCard.cardPartialPan);
    localStorage.setItem('cardExpireYear', this.creditCard.cardExpireYear);
    localStorage.setItem('cardSphToken', this.creditCard.cardSphToken);
    if (deviceId !== null) {
      localStorage.setItem('deviceId', this.deviceId);
    }
  }

  clearCreditCard() {
    this.creditCard.cardToken = null;
    this.creditCard.cardType = null;
    this.creditCard.cardPartialPan = null;
    this.creditCard.cardExpireYear = null;
    this.creditCard.cardSphToken = null;
    localStorage.removeItem('cardToken');
    localStorage.removeItem('cardType');
    localStorage.removeItem('cardPartialPan');
    localStorage.removeItem('cardExpireYear');
    localStorage.removeItem('cardSphToken');
    localStorage.removeItem('deviceId');
  }
}

export default UserStore;
