import React from 'react';
import './Content.css';

const Content = (props) => {
  return (
    <div className="content">
      {props.banner}
      <main>
        {props.children}
      </main>
    </div>
  );
};

export default Content;
