import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import './NotificationOngoingCharge.css';

const NotificationOngoingCharge = inject('intl')(observer(class NotificationOngoingCharge extends Component {

  openCharge() {
    this.props.history.push('/charging/' + this.props.activeCharge.otpToken);
  }

  render() {
    if(this.props.location.pathname.startsWith('/charging/')) {
      return null;
    }

    return (
      <div className="notification-ongoing-charge">
        <Card className="box">
          <div className="notification-ongoing-charge-progress">
            {this.props.intl.getMessage('chargingongoing_recent_or_current_event_available')}
      	  </div>

          <Button variant={"contained"}
                  onClick={this.openCharge.bind(this)}
                  primary={true}
          >
            {this.props.intl.getMessage('chargingongoing_open')}
          </Button>
    	  </Card>
      </div>
    );
  }
}));

export default withRouter(NotificationOngoingCharge);
