import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AddCardForm from './AddCardForm';
import { inject, observer } from 'mobx-react';
import ChargingSocketSelector from './ChargingSocketSelector';
import LoadingIndicator from './LoadingIndicator';
import ChargingPriceTable from './ChargingPriceTable';
import CreditCardInfo from './CreditCardInfo';
import Dialog from "@mui/material/Dialog";
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import 'url-search-params-polyfill';
import Content from './Content';
import Banner from './Banner';
import ImpressumLink from './ImpressumLink';
import BannerAdvertisement from './BannerAdvertisement';
import StationType from './StationType';
import { parse as qsParse } from 'qs';
import { Card, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import {shouldUseDeviceIdForOtpCharge, isAuthHoldEnabled} from '../services/feature-flag';
import {getLocalPriceWithCurrency} from "../utils/formatters";
const R = require('ramda')


const PayButtonStyles = {
  width: 'auto',
  display: 'inline-block',
  margin: '16px auto auto'
};

const StationState = {
  available: 'AVAILABLE',
  inUse: 'IN_USE',
  unavailable: 'UNAVAILABLE'
};

const ErrorDialog = inject("intl")(props => {
  const { intl, isOpen, message, onClose, title } = props;
  const dialogTitle = title ? title : intl.getMessage("creditcardinfo_error_dialog_title");
  const CloseButton = (
    <Button
      variant={"contained"}
      onClick={onClose}
    >
      {intl.getMessage("stationsearch_label_ok")}
    </Button>
  );
  return (
    <Dialog
      actions={CloseButton}
      contentStyle={{ maxWidth: "500px" }}
      modal={true}
      onClose={onClose}
      open={isOpen}
      title={dialogTitle}
    >
      {message}
    </Dialog>
  );
});

const Station = inject('store', 'customization', 'intl', 'user')(observer(class Station extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: true,
      loadingMessage: 'progressloader_loading',
      socketSelectionRequired: false,
      activeSocket: null,
      cardFailureCause: "",
      cardChecked: false,
      open: false,
      userWantsToStopChargeWithCardEnding: false,
      cardEnding: "",
      errorMsg: "",
      errorDialog: {
        isOpen: false,
        message: "",
        title: ""
      },
      station: null,
      authHoldEnabled: false,
    };

    this.onChangeSocket = this.onChangeSocket.bind(this);
  }

  getCustomization(path) {
    return R.path(path.split('.'), this.props.customization);
  }

  mapStationToStationState = (station) => {
    switch (station.status) {
      case 'free':
        return StationState.available;
      case 'inuse':
        return StationState.inUse;
      default: // 'broken', 'disabled', 'offline'
        return StationState.unavailable;
    }
  };

  setSocketSelection({ connector_info: connectorInfo }) {
    if (!connectorInfo) return;

    const socketList = new Set();
    connectorInfo.map(info => socketList.add(info.connector_id));

    // if there is only 1 connector_ID from the connector_info array, set it as active socket
    if (socketList.size === 1) {
      const activeSocket = socketList.values().next().value
      return this.setState({
        activeSocket,
        socketSelectionRequired: false
      });
    }

    // else require user to select a socket
    return this.setState({ socketSelectionRequired: true });
  }

  checkForPaymentCardAddingError() {
    const queryStrings = qsParse(this.props.location.search, { ignoreQueryPrefix: true });
    const response = queryStrings.response || "success";
    if (response === "addcard_failure") {
      const cause = queryStrings.cause || "creditcardinfo_error_unknown";
      const message = this.cardErrorMessage(cause);
      this.setState({ errorDialog: { isOpen: true, message } });
    }
  }

  refreshStation() {
    const stationId = this.props.match.params.id;
    fetch(`/api/stations/${stationId}`)
      .then(res => res.json())
      .then(station => {
        this.setState({
          station: station,
          stationState: this.mapStationToStationState(station),
          isLoading: false
        });
        this.setSocketSelection(station);
        this.checkForPaymentCardAddingError();
        this.authHoldEnabled();
      })
      .catch(err => {
        console.error(err);
        this.props.history.push('/');
      });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (!newProps.history.location.search) {
      this.setState({ cardChecked: false });
    }
  }

  authHoldEnabled() {
    let otpSeller;
    let station = this.state.station;
    if ('undefined' === typeof station.seller.otpSeller) {
      otpSeller = station.seller_ID;
    } else {
      otpSeller = station.seller.otpSeller;
    }
    const result = isAuthHoldEnabled(otpSeller).then((ret) => {
      this.setState({authHoldEnabled: ret});
    });
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    if (query && query.get) {
      const cardToken = query.get('cardToken');
      let cardType = query.get('cardType');
      /*
      Mapping cardType returned from PSP microservice with images stored in virta-white
      to display added cards correctly.
      */
      if (cardType === 'mc') {
        cardType = 'mastercard';
      }

      const cardPartialPan = query.get('cardPartialPan');
      const cardExpireYear = query.get('cardExpireYear');
      const cardSphToken = query.get('cardSphToken');
      const deviceId = query.get('deviceId');
      if (cardToken && cardType && cardPartialPan && cardSphToken) {
        this.props.user.saveCreditCard({
          cardToken,
          cardType,
          cardPartialPan,
          cardExpireYear,
          cardSphToken
        }, deviceId);
        this.setState({ cardChecked: true });
      }
    }

    this.refreshStation();
  }

  startCharging = async () => {
    const { activeSocket } = this.state;
    if (!activeSocket) {
      alert(this.props.intl.getMessage('station_choose_socket_type'));
      return;
    }

    this.setState({
      isLoading: true,
      loadingMessage: 'chargedisplay_starting'
    });

    const stationId = parseInt(this.props.match.params.id, 10);
    let { deviceId, creditCard: { cardToken, cardType, cardPartialPan, cardExpireYear, cardSphToken } } = this.props.user;

    let url = `/api/charging/startonetime/${stationId}?token=${cardToken}&cardType=${cardType}&cardPartialPan=${cardPartialPan}&cardExpireYear=${cardExpireYear}&cardSphToken=${cardSphToken}&socket=${activeSocket}`

    const usingV2Endpoint = await shouldUseDeviceIdForOtpCharge(
      this.state.station.seller_ID,
    );

    if (usingV2Endpoint) {
      if (deviceId == null) {
        deviceId = await fetch('/api/v4/customers/deviceId', { method: 'POST'})
            .then(res => {
              if (res.status === 200) {
                return res.json();
              } else {
                return Promise.reject();
              }
            })
            .catch(error => console.error(error));
        deviceId =  deviceId.deviceId;
      }
      url = `/api/charging/startonetime-v2/${stationId}?socket=${activeSocket}&deviceId=${deviceId}&sellerId=${this.state.station.seller_ID}`;
    }

    fetch(url)
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          return Promise.reject();
        }
      })
      .then(response => {
        if (response.status && response.status !== 200) {
          return Promise.reject(response.status + ' ' + (response.message || ''));
        }

        const { stopcode, otpToken } = response;

        this.props.store.setActiveCharge({
          usingV2Endpoint,
          stationId,
          otpToken,
          stopcode,
          deviceId,
          receiptSent: false,
        });

        this.setState({
          isLoading: false,
          loadingMessage: 'progressloader_loading'
        });

        return otpToken;
      })
      .then(otpToken => {
        // here we know that the charge has been started successfully, saving the state so the next step knows that a charge has been initiated
        localStorage.setItem('charge_start_initiated_by_customer', true);
        this.props.history.push({
          pathname: `/charging/${otpToken}`
        });
      })
      .catch(err => {
        console.error('Error starting charge: ', err);
        this.setState({
          open: true,
          isLoading: false,
          errorMsg: this.props.intl.getMessage('station_could_not_start_charging')
        });
      });
  };

  handleInfoDialogClose = () => {
    this.setState({ errorDialog: { isOpen: false } });
  };

  handleRequestClose = () => {
    this.setState({
      open: false,
    });
  };

  onChangeSocket(key, value) {
    this.setState({
      activeSocket: key
    });
  }

  handleCardEndingChange = (event) => {
    this.setState({ cardEnding: event.target.value });
  }

  stopActiveCharge = () => {
    if (this.props.store.activeCharge) {
      this.setState({
        isLoading: true,
        loadingMessage: 'chargedisplay_stopping',
      });

      this.props.store.stopCharging()
        .then(() => {
          this.props.history.push(`/charging/${this.props.store.activeCharge.otpToken}`);
        })
        .catch((errorStatusCode) => {
          const message = this.getErrorMessageFromStatusCode(errorStatusCode);
          this.setState({
            isLoading: false,
            loadingMessage: 'progressloader_loading',
            errorDialog: {
              isOpen: true,
              title: this.props.intl.getMessage('chargingongoing_stop_charging_failed'),
              message,
            }
          });
        })
    } else {
      this.setState({ userWantsToStopChargeWithCardEnding: true });
    }
  }

  getErrorMessageFromStatusCode(statusCode) {
    const { getMessage } = this.props.intl;
    let message;
    switch (statusCode) {
      case 400:
        message = getMessage('chargingongoing_stop_charging_failed');
        break;
      case 429:
        message = getMessage('error_too_many_requests');
        break;
      default:
        message = getMessage('error');
    }
    return message;
  }

  stopChargingWithCardEnding = () => {
    const stationId = this.props.match.params.id;
    const { cardEnding, station: { seller_ID: sellerId } } = this.state;
    this.setState({
      isLoading: true,
      loadingMessage: 'chargedisplay_stopping',
    });
    this.props.store.stopCharging(stationId, cardEnding, sellerId)
      .then((response) => {
        this.props.history.push(`/charging/${response.otpToken}`);
      })
      .catch((errorStatusCode) => {
        const { getMessage } = this.props.intl;
        const message = errorStatusCode === 400 ? getMessage('chargingongoing_stop_cardending_error') : this.getErrorMessageFromStatusCode(errorStatusCode);
        this.setState({
          isLoading: false,
          loadingMessage: 'progressloader_loading',
          errorDialog: {
            isOpen: true,
            message,
            title: getMessage('chargingongoing_stop_charging_failed'),
          },
        });
      });
  }

  cancelStopChargingWithCardEnding = () => {
    this.setState({ userWantsToStopChargeWithCardEnding: false });
  }

  renderMessage = () => {
    return (<Snackbar
      open={this.state.open}
      message={<span id="message-id">{this.state.errorMsg}</span>}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={this.handleRequestClose}
        >
          <Close />
        </IconButton>,
      ]}
      autoHideDuration={4000}
      onClose={this.handleRequestClose}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
    />);
  };

  cardErrorMessage = (cause) => {
    if (!cause) {
      return "";
    }

    return this.props.intl.getMessage(cause) ||
      this.props.intl.getMessage("creditcardinfo_error_unknown") ||
      "";
  };

  subComponentPropertiesAndVisibilityRules = () => {
    const rules = {
      statusText: null,
      startChargingAvailable: false,
      showChargingPriceTable: !this.state.cardChecked,
      showStationBanner: !this.state.cardChecked,
      showAddCardForm: false,
      showCreditCardInfo: false,
      showStatusText: false,
      showChargeStopControl: false,
      showTerms: false
    };

    switch (this.state.stationState) {
      case StationState.available:
        rules.startChargingAvailable = Boolean(this.state.cardChecked || this.state.station.free_usage);
        rules.showAddCardForm = !rules.startChargingAvailable;
        rules.showCreditCardInfo = this.state.cardChecked;
        rules.showTerms = true;
        break;
      case StationState.inUse:
        rules.statusText = this.props.intl.getMessage('station_status_in_use');
        rules.showChargeStopControl = true;
        rules.showStatusText = true;
        break;
      default:
        rules.statusText = this.props.intl.getMessage('station_status_unavailable');
        rules.showStatusText = true;
        break;
    }
    return rules;
  };

  subComponents = (
    statusText,
    showStationBanner,
    showChargeStopControl,
  ) => {
    const components = {};
    const receiptInputFields = this.getCustomization('host.service.activeCustomization.settings.receiptInputFields');
    let otpReceipt = false;
    if (this.getCustomization('host.service.activeCustomization.settings.otpReceiptSettings')) {
      otpReceipt = this.getCustomization('host.service.activeCustomization.settings.otpReceiptSettings').otpReceipt;
    }
    // This is shown before the card is checked
    components.addCardForm = (
      <div>
        <AddCardForm
          otpReceipt={otpReceipt}
          receiptInputFields={receiptInputFields}
          location={this.props.location}
          station={this.state.station}
          setError={(message) => this.setState({ errorDialog: { isOpen: true, message } })}
        />
      </div>);

    components.creditCardInfo =
      (this.props.user.creditCard.cardPartialPan && this.props.user.creditCard.cardType)
        ? (<div>
          <CreditCardInfo cardType={this.props.user.creditCard.cardType}
            cardPartialPan={this.props.user.creditCard.cardPartialPan}
            intl={this.props.intl} />
          <div className="wrapper__inner wrapper--center">
            {this.state.open && this.renderMessage()}
          </div>
        </div>)
        : null;

    const stopChargeWithCardEndingBox = (
      <Typography component={"div"}>
        <Typography component={"p"}>
          Are you charging here? To stop charging please enter the last 4 digits of the payment card you used
        </Typography>
        <TextField
          id="cardEnding"
          value={this.state.cardEnding}
          onChange={this.handleCardEndingChange}
          type="number"
          maxLength="4"
          min="0"
          placeholder="1234"
          fullWidth={true}
          inputMode={"numeric"}
          inputStyle={{ textAlign: 'center' }}
        />
        <Button
          variant={"contained"}
          id="stopChargingWithCardEndingBtn"
          onClick={this.stopChargingWithCardEnding}
          primary={true}
          type="button"
          disabled={this.state.cardEnding.length !== 4}
          style={{ marginTop: '8px' }}
        >{this.props.intl.getMessage('chargingongoing_stop_charging')}</Button>
        <Button
          variant={"contained"}
          onClick={this.cancelStopChargingWithCardEnding}
          type="button"
          style={{ marginTop: '8px', marginLeft: '8px' }}
        >{this.props.intl.getMessage('cancel')}</Button>
      </Typography>
    );

    const stopActiveChargeButton = (
      <Button
        variant={"contained"}
        id="stopChargingBtn"
        onClick={this.stopActiveCharge}
        style={PayButtonStyles}
        primary={true}
      >{this.props.intl.getMessage('station_stop_charging')}</Button>
    );

    const chargeStopControlBox = (
      <div style={{ width: '100%', textAlign: 'center' }}>
        {
          this.state.userWantsToStopChargeWithCardEnding
            ? stopChargeWithCardEndingBox
            : stopActiveChargeButton
        }
      </div>
    );

    components.statusBox = (
      <Card id="statusBox" className="box">
        <div className="wrapper__inner wrapper--center">
          <p>{this.props.intl.getMessage('station_charger_is_currently')} <b>{statusText}</b></p>
          {showChargeStopControl && chargeStopControlBox}
        </div>
      </Card>
    );

    let connectorInfo = this.state.station.connector_info;
    components.socketSelector = (
      <ChargingSocketSelector divId="chargeSocketSelector"
        connectorInfo={connectorInfo}
        socketSelectionRequired={this.state.socketSelectionRequired}
        activeSocket={this.state.activeSocket}
        onChangeSocket={this.onChangeSocket}
        intl={this.props.intl}>
      </ChargingSocketSelector>
    );

    components.startButton = (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Button id="startCharging"
          variant={"contained"}
          onClick={this.startCharging}
          style={PayButtonStyles} primary={true}>
          {this.props.intl.getMessage('station_start_charge')}
        </Button>
      </div>
    );

    const authPrice = getLocalPriceWithCurrency(this.state.station.oneTimeAuthorizationholdAmountCents, this.state.station.oneTimeAuthorizationholdCurrency);

    components.authhold = (this.state.authHoldEnabled && this.state.station.oneTimeAuthorizationholdAmountCents > 0) ?
        (<p style={{margin: '20px auto', width: '75%'}}>
            {this.props.intl.getMessageWithParameters('auth_hold_agree_text_latest', { 'amount_and_currency': authPrice })}
            </p>) : React.createElement('span', {"class": "noAuthHold"}, '');

    const terms =
      R.path(['brand', 'settings', 'termsAndConditions', 'url'], this.props.customization) ||
      'https://www.virta.fi/docs/terms_and_conditions.pdf';
    components.termsParagraph = (<p className='add-card-form-terms'>
      {this.props.intl.getMessage('addcardform_by_clicking')}
      &nbsp;
      <a href={terms}>
        {this.props.intl.getMessage('addcardform_terms_and_conditions')}
      </a>
    </p>
    );

    let socketTypes = connectorInfo
      ? connectorInfo.map(info => info.type).join(',')
      : this.state.station.stationType.sockettypes;

    components.stationBanner = !showStationBanner ? "" : (
      <Banner>
        <h2 style={{ fontSize: '16px', color: this.props.customization.theme.brandColor }}>
          {this.props.intl.getMessage('station_charger_id')}: {this.state.station.station_ID}
        </h2>
        {(this.state.station.seller.roaming_identifier_cpo)
          ? (this.state.station.custom_evse_id)
            ? <h1 style={{ fontSize: '14px', color: this.props.customization.theme.brandColor }}> {this.props.intl.getMessage('station_charger_custom_id')}: {this.state.station.seller.roaming_identifier_cpo}*E{this.state.station.custom_evse_id}</h1>
            : <h1 style={{ fontSize: '14px', color: this.props.customization.theme.brandColor }}> {this.props.intl.getMessage('station_charger_custom_id')}: {this.state.station.seller.roaming_identifier_cpo}*E{this.state.station.station_ID}</h1>
          : ''
        }
        <p style={{ fontSize: '12px', marginBottom: '4px' }}>
          {this.state.station.name}
        </p>
        <p style={{ fontSize: '12px', marginTop: '0' }}>
          <StationType type={socketTypes} />
        </p>
        <BannerAdvertisement {...this.props.customization.theme.bannerAdvertisement} />
      </Banner>
    );

    components.chargingPriceTable = (
      <div className="wrapper">
        <ChargingPriceTable brandColor={this.props.customization.theme.brandColor}
          station={this.state.station} intl={this.props.intl} />
      </div>
    );

    return components;
  };

  render() {
    if (!this.state.station) {
      return (
        <main className="main">
          <LoadingIndicator intl={this.props.intl} loadingMessage={this.state.loadingMessage} />
        </main>
      );
    }

    const {
      statusText,
      showStatusText,
      startChargingAvailable,
      showChargingPriceTable,
      showStationBanner,
      showAddCardForm,
      showCreditCardInfo,
      showTerms,
      showChargeStopControl,
    } = this.subComponentPropertiesAndVisibilityRules();

    const {
      stationBanner,
      chargingPriceTable,
      creditCardInfo,
      addCardForm,
      statusBox,
      socketSelector,
      startButton,
      authhold,
      termsParagraph,
    } = this.subComponents(
      statusText,
      showStationBanner,
      showChargeStopControl,
    );

    return (
      <Content banner={stationBanner}>
        <Card className="box">
          {showChargingPriceTable && chargingPriceTable}
          {showCreditCardInfo && creditCardInfo}
          {this.state.isLoading
            ? <LoadingIndicator intl={this.props.intl} loadingMessage={this.state.loadingMessage} />
            : <div>
              {showAddCardForm && addCardForm}
              {showStatusText && statusBox}
              {startChargingAvailable && socketSelector}
              {startChargingAvailable && startButton}
              {authhold}
              {showTerms && termsParagraph}
            </div>
          }
          {
            (
                <p style={{margin: 'auto', width: '75%', fontSize: 'smaller'}}>
                  {this.props.intl.getMessage('payment_encrypted_device_details_locally_stored')}
                </p>
            )
          }
          { this.props.customization.impressumLink && (
            <div style={{width:'75%', padding: '32px 32px 0 32px', margin: '0 18px'}}>
              <ImpressumLink intl={this.props.intl} link={this.props.customization.impressumLink}/>
            </div>
          )}
        </Card>
        <ErrorDialog
          isOpen={this.state.errorDialog.isOpen}
          message={this.state.errorDialog.message}
          title={this.state.errorDialog.title}
          onClose={this.handleInfoDialogClose} />
      </Content>
    );
  }
}));

export default withRouter(Station);
