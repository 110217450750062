import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Content from './Content';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Banner from './Banner';
import { formatKwh, formatDurationString } from '../utils/formatters';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { strxml2json } from "../utils/parsers";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Typography
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const R = require('ramda')

const fallbackCopyTextToClipboard = text => {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
};
const copyTextToClipboard = text => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
  } else {
    navigator.clipboard.writeText(text).then(
      () => { },
      (err) => console.error('Async: Could not copy text: ', err)
    );
  }
};

const fieldContainerStyle = { width: 'calc(100% - 48px)', display: 'inline-block' };
const textareaStyle = { width: '100%', border: '1px solid black' };
const labelStyle = { bottom: 0 };
const iconButtonStyle = { verticalAlign: 'top' };

const StaticInputWithCopyToClipboard = (props) => (
  <div>
    <div style={fieldContainerStyle}>
      <label style={labelStyle}>{props.title}</label>
      <textarea rows={props.rows} readOnly={true} value={props.value} style={textareaStyle} />
    </div>
    <IconButton onClick={() => copyTextToClipboard(props.value)} style={iconButtonStyle}>
      <i className="material-icons">file_copy</i>
    </IconButton>
  </div>
);

StaticInputWithCopyToClipboard.defaultProps = {
  rows: 1
};

const SignedMeterValueClipboard = ({ begin, beginPk, end, endPk }) => (
  <div>
    {begin && begin !== '0' && <StaticInputWithCopyToClipboard value={begin} title={'Start:'} rows={4} />}
    {begin && begin !== '0' && <StaticInputWithCopyToClipboard value={beginPk} title={'Public key:'} rows={1} />}
    {begin && begin !== '0' && end && end !== '0' && <Divider />}
    {end && end !== '0' && <StaticInputWithCopyToClipboard value={end} title={'End:'} rows={4} />}
    {end && end !== '0' && <StaticInputWithCopyToClipboard value={endPk} title={'Public key:'} rows={1} />}
  </div>
);

const EichrechtInfoTypeSAM = () => (
  <div>
    <p>Vielen Dank fürs Laden! Sie können die Details zu Ihrem Ladevorgang am Display der Ladestation vor Ihnen ablesen. Falls Sie die Daten aufbewahren möchten, empfehlen wir Ihnen ein Foto von dem Display zu machen.</p>
    <p>Gute Fahrt!</p>
    <br />

    -------

    <p>Thank you for charging! Feel free to check the details of the charge event on the display of the charger in front of you. If you want to save the data we recommend that you take a picture of the display.</p>
    <p>Have a great journey!</p>
  </div>
);

const SignedMeterValueDialog = inject("intl")(observer(class SignedMeterValue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  toggleDialogStatus = () => this.setState(state => ({ isOpen: !state.isOpen }));

  render() {
    let { begin, end, eichrecht_type } = this.props;

    if (begin || end || eichrecht_type === 'SAM') {

      const beginPk = R.path(['signedMeterValue', 'publicKey'], strxml2json(begin));
      const endPk = R.path(['signedMeterValue', 'publicKey'], strxml2json(end));

      return (
        <div style={{ marginTop: '16px', marginBottom: '16px' }}>
          <Dialog
            actions={[<Button variant={"contained"} key='ok' label={'Ok'} onClick={this.toggleDialogStatus} />]}
            contentStyle={{ maxWidth: "500px" }}
            modal={true}
            onClose={this.toggleDialogStatus}
            open={this.state.isOpen}
            title={this.props.intl.getMessage('chargingfinished_eichrecht_info')}>
            {
              eichrecht_type === 'SAM' ?
                <EichrechtInfoTypeSAM /> :
                <SignedMeterValueClipboard begin={begin} beginPk={beginPk} end={end} endPk={endPk} />
            }
          </Dialog>
          <Button
            variant={"contained"}
            id="signedMeterValue"
            fullWidth={true}
            style={{ marginTop: '16px' }}
            primary={true} type="button"
            onClick={this.toggleDialogStatus}
          >{'Show signed meter value'}</Button>
        </div>
      );
    } else {
      return null;
    }
  }
}));

const ChargingFinished = inject('store', 'intl', 'customization', 'user')(observer(class ChargingFinished extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      email: localStorage.getItem(`receiptField_email`) || '',
      dialogOpen: false,
      dialogMessage: '',
    };
  }

  componentDidMount() {
    this.props.store.checkCharging(this.props.match.params.token)
      .then((onetimecharge) => {
        const otpReceipt = this.props.customization.settings.otpReceiptSettings ?
          this.props.customization.settings.otpReceiptSettings.otpReceipt : "optional";
        if (otpReceipt === "mandatory") {
          if (!this.props.store.activeCharge.receiptSent) {
            this.props.store.setChargeReceiptSent();
            this.sendReceipt(this.state.email, false);
          }
        }
        this.setState({ onetimecharge });
      });
  }

  getCustomization(path) {
    return R.path(path.split('.'), this.props.customization);
  }

  sendReceipt(email, showDialog) {
    const deviceId = this.props.store.activeCharge.deviceId;
    const token = this.props.match.params.token;
    const lang = this.props.intl.locale;
    const receiptInputFields = this.getCustomization('host.service.activeCustomization.settings.receiptInputFields') || {};
    delete receiptInputFields.email;
    const activeFieldNames =
      Object.keys(receiptInputFields).filter(fieldName =>
        receiptInputFields[fieldName] !== 'off');
    const isActive = fieldName => activeFieldNames.indexOf(fieldName) >= 0;
    const receiptUriParts =
      Object.keys(receiptInputFields).map(fieldName =>
        `&${fieldName}=${encodeURIComponent((isActive(fieldName) && localStorage.getItem(`receiptField_${fieldName}`)) || '')}`).join('');
    const url = `/api/charging/receiptonetime/?email=${email}&token=${token}&deviceId=${deviceId}&lang=${lang}${receiptUriParts}`;

    fetch(url)
      .then(response => {
        if (response.status !== 200) {
          return Promise.reject();
        } else {
          if (showDialog) {
            this.setState({
              dialogOpen: true,
              dialogMessage: this.props.intl.getMessage('chargingfinished_receipt_sent'),
            });
          }
          return Promise.resolve();
        }
      })
      .catch(err => {
        console.error(err);
        this.setState({
          dialogOpen: true,
          dialogMessage: this.props.intl.getMessage('chargingfinished_error_sending_receipt_dialog')
        });
      }).finally(() => {
        this.setState({
          receiptSent: true
        });
      });
  }

  handleFormSubmit(e) {
    e.preventDefault();

    if (!this.state.email) {
      this.setState({
        dialogOpen: true,
        dialogMessage: this.props.intl.getMessage('chargingfinished_enter_email')
      });
      return false;
    } else {
      this.sendReceipt(this.state.email, true);
      return false;
    }
  }

  handleEmailChange = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  handleRequestClose = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  handleChargingFinished = () => {
    this.props.store.clearActiveCharge();
    this.props.history.push('/');
  };

  receiptForm = () => {
    const customization = this.props.customization;

    // Check for disabled or forced receipt sending
    if (customization.settings.otpReceiptSettings) {
      const otpReceipt = customization.settings.otpReceiptSettings.otpReceipt;
      if (otpReceipt === "off" || otpReceipt === "mandatory") {
        return (<span />);
      }
    }

    return (
      <form onSubmit={this.handleFormSubmit.bind(this)}>
        <div>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel-receipt-header">
              <Typography fontSize={"14px"} style={{textTransform: "uppercase"}}>
                {this.props.intl.getMessage('chargingfinished_send_receipt')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                id="email-receipt"
                value={this.state.email}
                onChange={this.handleEmailChange}
                type="email"
                label={this.props.intl.getMessage('chargingfinished_email_label')}
                fullWidth={true}
                //style={{ marginTop: '-32px' }}
              />
              <Button
                variant={"contained"}
                color={"primary"}
                type="submit"
                style={{ marginTop: '8px' }}
              >{this.props.intl.getMessage('chargingfinished_send_label')}</Button>
            </AccordionDetails>
          </Accordion>
        </div>
      </form>
    );
  };

  render() {
    if (!this.state.onetimecharge) {
      return <div />;
    }

    const customization = this.props.customization;
    const onetimecharge = this.state.onetimecharge;
    const { price, currency, power, durationSeconds } = onetimecharge.receiptFields;
    const { signed_metervalue_begin, signed_metervalue_end } = onetimecharge.charge;

    const actions = (
      <Button variant={"contained"}
              onClick={() => this.setState({ dialogOpen: false })}
      >OK</Button>
    );
    return (
      <div>
        <Content
          banner={
            <Banner>
              <h4 id="chargeThanksForUsingMsg"
                style={{ color: customization.theme.brandColor, textAlign: 'center', fontWeight: 'bold' }}>
                {this.props.intl.getMessage('chargingfinished_thanks_for_using')}
              </h4>
            </Banner>}>
          <Card className="box">
            <div className="wrapper">
              <h3 id="chargingCompleted" className="title" style={{ borderBottom: '1px solid #5fb100', paddingBottom: '24px' }}>
                <span style={{ position: 'relative', right: '8px', top: '12px' }}>
                  <i className="material-icons md-48" style={{ color: '#5fb100', fontSize: '36px' }}>check_circle</i>
                </span>
                {this.props.intl.getMessage('chargingfinished_charging_completed')}
              </h3>
            </div>
            <div className="wrapper flex">
              <div className="chargingFinished">
                <h2 className="chargingValue">{price}</h2>
                <h5 className="chargingTitle">{currency}</h5>
              </div>
              <div className="chargingFinished">
                <h2 className="chargingValue">{formatKwh(power)}</h2>
                <h5 className="chargingTitle">kWh</h5>
              </div>
            </div>
            <div className="wrapper">
              <h3 className="title" style={{ textAlign: 'center', marginBottom: '0', marginTop: '0' }}>
                {this.props.intl.getMessage('chargingfinished_duration')}
              </h3>
              <h5 id="chargeDurationAtEnd" className="title"
                style={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center', marginTop: '10px' }}>
                {formatDurationString(durationSeconds, this.props.intl.getMessage) || 'N/A'}
              </h5>
            </div>

            <SignedMeterValueDialog eichrecht_type={onetimecharge.charge.eichrecht_type} begin={signed_metervalue_begin} end={signed_metervalue_end} />

            {this.receiptForm()}

            <div style={{ marginTop: '16px' }}>
              {customization.registerPageUrl &&
                <Button href={customization.registerPageUrl} target="_blank"
                        variant={"contained"}
                        fullWidth={true}
                        type="submit">
                  {this.props.intl.getMessage('chargingfinished_register_as_customer')}
                </Button>
              }
              <Button
                id="chargingFinished"
                fullWidth={true}
                style={{ marginTop: '16px' }}
                primary={true} type="button"
                onClick={this.handleChargingFinished.bind(this)}
              >
                {this.props.intl.getMessage('chargingfinished_done_label')}
              </Button>
            </div>

          </Card>
        </Content>
        <Dialog
          contentStyle={{ maxWidth: "500px" }}
          actions={actions}
          modal={false}
          open={this.state.dialogOpen}
          onClose={this.handleRequestClose}
        >{this.state.dialogMessage}</Dialog>
      </div>
    );
  }
}));

export default withRouter(ChargingFinished);
